window.gon = {
    config: {
        // Auth0 configuration values
        AUTH0_API_CLIENT_ID: 'lAGrV8zoYlhai0LlodKMjSiLCZY3ox6c',
        AUTH0_API_CLIENT_SECRET: '<%= decrypt "caVu0ubiPTMfu4VxfhmwQVgsdLlEcMjqg0m9IVuxYQeuqIddnWj7CDcL/tM1fOK0peNta84thg4zf4P86LMAS3IPT7OoBpDe2ZjYVoLxd/7h80F+SZSx5A==" %>',
        AUTH0_DOMAIN_URL: 'https://prd-patientfusion.auth0.com',
        PATIENT_PORTAL_AUTH0_CLIENT_ID: '5s571lL06q9y3LxN7EaygckjgPHR20nU',
        PHR_AUTH_TOKEN_KEY: 'phr_auth_token',

        // UI configuration values
        AGREEMENT_ENDPOINT_URL: 'https://agreement-endpoint.patientfusion.com/api/v1',
        APPOINTMENT_ENDPOINT_URL: 'https://appointment-endpoint.patientfusion.com',
        AUDITR_ENDPOINT_URL: 'https://auditr-endpoint.patientfusion.com',
        BILLING_ENDPOINT_URL: 'https://billing-endpoint.patientfusion.com',
        CCDA_ENDPOINT_URL: 'https://ccda-endpoint.patientfusion.com',
        CLINICAL_ENDPOINT_URL: 'https://clinical-endpoint.patientfusion.com/api/v1',
        DIRECT_ENDPOINT_URL: 'https://api.practicefusion.com/direct/v1',
        INTAKE_ENDPOINT_URL: 'https://intake.patientfusion.com/api/v1',
        MESSAGE_ENDPOINT_URL: 'https://message-endpoint.patientfusion.com/api/v1',
        NOTIFICATION_ENDPOINT_URL: 'https://notification-endpoint.patientfusion.com/api/v1',
        PATIENT_RECORD_ENDPOINT_URL: 'https://patient-record-endpoint.patientfusion.com/api/v1',
        PHR_AUTHN_ENDPOINT_URL: 'https://phr-authn-endpoint.patientfusion.com/api/v1',
        PHR_AUTHN_ENDPOINT_URL_V3: 'https://phr-authn-endpoint.patientfusion.com/api/v3',
        PHR_HOME_URL: 'https://my.patientfusion.com',
        PHR_PATIENT_ENDPOINT_URL: 'https://phr-patient-endpoint.patientfusion.com/api/v2',

        PHR_USER_ENDPOINT_URL: 'https://phr-user-endpoint.patientfusion.com/api/v2',
        PHR_USER_ENDPOINT_URL_v1: 'https://phr-user-endpoint.patientfusion.com/api/v1',

        PRACTICE_ENDPOINT_URL: 'https://practice-endpoint.patientfusion.com',
        SCHEDULE_AVAILABILITY_ENDPOINT_URL: 'https://www.patientfusion.com/api/v1',
        TASK_ENDPOINT_URL: 'https://task-endpoint.patientfusion.com',

        // service configuration values
        INTAKE_URL: 'https://intake.patientfusion.com',
        PF_LOGIN_URL : 'https://login.patientfusion.com',
        PHR_AUTHN_FOUNDATION_URL: 'https://phr-authn-foundation.patientfusion.net',
        PHR_SERVICES_HOST: 'https://consumer.patientfusion.net/PhrServicesComposite',
        PHR_USER_DOMAIN_URL: 'https://phr-user-domain.patientfusion.net',
        TASK_CREATION_URL: 'https://my.patientfusion.com/register/task',
        TASK_DOMAIN_URL: 'https://task-domain.patientfusion.net'
    },
    env: 'production'
};